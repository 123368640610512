<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    methods: {
      ensure_connected: function () {
        console.log('connecting')
      },
    },
  }
</script>
