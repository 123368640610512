import Vue from 'vue'
import Vuex from 'vuex'

// Import data from JSON in src/:
// import foo from './file.json'

Vue.use(Vuex)

let apiUrl = process.env.VUE_APP_API_URL_PROD;

switch (process.env.NODE_ENV) {
  case 'development':
    apiUrl = process.env.VUE_APP_API_URL_DEV;
    break
  case 'staging':
    apiUrl = process.env.VUE_APP_API_URL_STAGE;
}

export default new Vuex.Store({
  state: {
    drawer: null,
    apiUrl: apiUrl,
    isLoggedIn: false,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {

  },
})
