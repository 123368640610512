import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);

var router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  // route names are an alias that can be used instead of the path in links. They are not visible to the user.
  // https://router.vuejs.org/guide/essentials/named-routes.html#named-routes
  routes: [
    {
      name: "signup",
      path: "/signup",
      component: () => import("@/views/dashboard/Signup"),
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/dashboard/Login"),
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Dashboard"),
      meta: {
        requiresAuth: true,
      },
      // Dashboard pages
      children: [
        {
          name: "home",
          path: "",
          component: () => import("@/views/dashboard/pages/Home"),
        },
        {
          name: "compensations",
          path: "compensations",
          component: () => import("@/views/dashboard/pages/Compensations"),
        },
        {
          name: "billing",
          path: "billing",
          component: () => import("@/views/dashboard/pages/Billing"),
        },
        {
          name: "account",
          path: "account",
          component: () => import("@/views/dashboard/pages/Account"),
        },
        {
          name: "settings",
          path: "settings",
          component: () => import("@/views/dashboard/pages/Settings"),
        },
      ],
    },
    {
      name: "pageNotFound",
      path: "*",
      component: () => import("@/views/dashboard/pages/PageNotFound"),
    },
  ],
});

// Redirect to login in case of 401
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      //store.dispatch('logout')
      router.push("/login").catch(() => {});
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.isLoggedIn) {
      axios
        .get(store.state.apiUrl + "/auth/me")
        .then((res) => {
          store.state.isLoggedIn = true;
          next();
        })
        .catch((err) => {
          if (err) {
            next({ name: "login", params: { nextUrl: to.fullPath } });
          }
        });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
