import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '@/sass/global.scss'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure

Vue.use(Vuetify)

const theme = {
  primary: '#8AC33E',
  secondary: '#8E7360',
  accent: '#8E7360',
  info: '#EEEAE8',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdiSvg' || 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
